/* Contenedor principal */
.genre-selection-container {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Título */
.genre-selection-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  max-width: 90%;
}

/* Estilo para el campo de texto */
.bands-textarea {
  width: 100%;
  max-width: 450px;
  height: 120px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  resize: none;
  line-height: 1.5;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.bands-textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

/* Botón de siguiente */
.next-button {
  display: block;
  width: 100%;
  max-width: 450px;
  padding: 12px;
  margin-top: 20px;
  background: #007bff;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.saltar-button {
  display: block;
  width: 100%;
  max-width: 450px;
  padding: 12px;
  margin-top: 40px;
  background: rgb(2, 76, 151);
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.next-button:hover {
  background: #0056b3;
  transform: scale(1.02);
}

/* Estilo para cada opción */
.genre-option {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  padding: 8px 14px;
  border-radius: 20px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}

.genre-dislike {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  padding: 8px 14px;
  border-radius: 20px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}

.genre-option:hover {
  background: #e0e0e0;
}

/* Estilos de input oculto */
.genre-option input {
  display: none;
}

.genre-dislike:hover {
  background: #e0e0e0;
}

/* Estilos de input oculto */
.genre-dislike input {
  display: none;
}

/* Marcar selección */
.genre-option.selected {
  background: #036;
  color: white;
  border-color: #036;
}

.genre-dislike.selected {
  background: rgb(112, 0, 0);
  color: white;
  border-color: rgb(112, 0, 0);
}

/* Contenedor de botones */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Botón de siguiente */
.next-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background: #036;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.next-button:hover {
  background: rgb(0, 40, 80);
}

/* Botón de retroceso */
.back-button {
  background: #6c757d;
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.back-button:hover {
  background: #5a6268;
}

/* Contenedor de géneros */
.genre-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

/* Géneros bloqueados en el segundo paso */
.disabled-genre {
  background: #d3d3d3 !important; /* Gris claro */
  color: #808080 !important; /* Texto gris */
  border: 1px solid #b0b0b0 !important;
  cursor: not-allowed; /* Mostrar que está deshabilitado */
  opacity: 0.6;
  display: none;
}

.genre-error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 480px) {
  .genre-selection-container {
    max-width: 90%;
    padding: 15px;
  }

  .bands-textarea {
    font-size: 14px;
    height: 100px;
  }

  .next-button {
    font-size: 16px;
    padding: 10px;
  }
}
