.feedback-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: transform 0.2s ease; /* Suave transición al hacer hover */
}

.feedback-icon:hover {
  transform: scale(1.1); /* Aumenta ligeramente el tamaño */
}

.feedback-disabled {
  color: #888;
  font-size: 14px;
  margin: 10px 0;
}

/* Deshabilitar efectos de hover en pantallas táctiles */
@media (hover: none) {
  .feedback-icon {
    transition: none;
  }

  .feedback-icon:hover {
    transform: none;
  }
}
