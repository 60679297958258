.concerts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 80px auto 20px; */
  padding: 20px;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f4f2ee;
}

.cards-container {
  margin: 80px auto 20px;
  padding: 20px;
  max-width: 1200px;
  padding: 20px;
}

.cards-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.cards-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  grid-template-columns: repeat(4, 1fr); /* Fuerza siempre 4 columnas */

  gap: 20px;
}

.concert-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Asegura que los elementos se distribuyan verticalmente */
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px #0000000d;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.concert-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: #f7f7f7; /* Fondo ligeramente gris */
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.concert-date {
  font-size: 14px;
  font-weight: bold;
  color: #333333; /* Gris oscuro */
}

.concert-emoji {
  font-size: 24px;
}

.card-body {
  padding: 10px 15px;
  flex-grow: 1; /* Permite que card-body crezca si es necesario */
}

.concert-band {
  font-size: 21px; /* Tamaño adecuado para títulos */
  font-weight: 600; /* Negrita para destacar */
  color: #333; /* Gris oscuro */
  overflow: hidden; /* Oculta cualquier desbordamiento de texto */
  margin: 0 0 10px;
  color: #333333; /* Gris oscuro */
}

.concert-genre,
.concert-venue,
.concert-time,
.concert-price {
  font-size: 14px;
  margin: 5px 0;
  color: #666666; /* Texto secundario */
}

.concert-price {
  font-weight: bold;
  color: #333333;
}

/* Clase para el efecto de parpadeo */
.blinking {
  animation: blinker 1s linear infinite; /* Duración de 1 segundo, repetición infinita */
}

/* Definición de la animación de parpadeo */

.loading-emoji {
  animation: blink 1s infinite; /* Animación de parpadeo */
  font-size: 20px; /* Ajusta el tamaño si es necesario */
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.concert-reason {
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.5rem;
  font-style: italic;
}

.card-share {
  position: absolute; /* Fija el elemento dentro del contenedor */
  bottom: 10px; /* Margen inferior */
  right: 10px; /* Margen derecho */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 10; /* Asegúrate de que esté por encima de otros elementos */
}

.whatsapp-share-button {
  align-items: center;
  background-color: #f7f7f7;
  border: 0.5px solid #4a4a4a;
  border-radius: 5px;
  color: #4a4a4a;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.whatsapp-share-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* .whatsapp-share-button:hover {
  background-color: #333333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.whatsapp-icon {
  height: 25px;
  width: 25px;
  margin-right: 8px; /* Espaciado entre el ícono y el textod */
}

/* Filtros */
.filters-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: #f7f7f7;
  max-width: 900px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  padding: 20px;
}

.filters-container label {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  font-weight: 600;
  color: #444;
  width: 100%;
}

.filters-container input,
.filters-container select {
  margin-top: 0.5rem;
  padding: 0.8rem;
  font-size: 0.95rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.2s ease;
  color: #333;
  background-color: #fafafa;
}

.filters-container input:focus,
.filters-container select:focus {
  border-color: #036;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
  outline: none;
}

.filters-container input::placeholder {
  color: #9e9e9e;
  font-size: 0.9rem;
}

.filters-container select {
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.7rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

.filters-container select option {
  font-size: 0.95rem;
  padding: 0.5rem;
}

.filters-container input[type="date"] {
  width: 100%; /* Ocupar todo el ancho disponible */
  max-width: 100%; /* Prevenir desbordamiento */
  font-size: 1rem; /* Ajustar tamaño de texto */
  padding: 0.8rem; /* Espacio interno adecuado */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fafafa;
  color: #333;
  box-sizing: border-box; /* Asegurar que padding no afecte el tamaño */
  appearance: none; /* Elimina estilos nativos en algunos navegadores */
}

.filters-container input[type="date"] {
  appearance: none; /* Elimina diseño nativo */
  -webkit-appearance: none; /* Para Safari */
  -moz-appearance: none; /* Para Firefox */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center; /* Ajusta la posición del ícono */
  background-size: 1.2em; /* Tamaño del ícono */
  padding-right: 3rem; /* Espacio para el ícono */
  width: 100%; /* Asegura que ocupe todo el contenedor */
  height: 2.5rem; /* Altura fija para consistencia */
  font-size: 1rem; /* Tamaño de texto */
  border: 1px solid #e0e0e0; /* Bordes */
  border-radius: 8px; /* Esquinas redondeadas */
  background-color: #fafafa; /* Fondo */
  color: #333; /* Texto */
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.filters-container input[type="date"]:focus {
  border-color: #036; /* Color de foco */
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2); /* Sombra al enfocarse */
  outline: none; /* Elimina borde predeterminado */
}

.filters-container input[type="date"]::placeholder {
  color: #aaa; /* Color del placeholder */
}

/* .bands-container {
  margin-top: 10px;
  padding: 5px 0;
}

.band-item {
  margin-bottom: 10px;
}

.spotify-title {
  font-size: 0.95rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  margin-top: 0px;
}

.spotify-link {
  color: #4a4a4a;
  font-size: 0.9rem;
  font-weight: normal;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.spotify-link:hover {
  color: #333333;
}

.external-link-icon {
  margin-left: 5px; 
  font-size: 0.8rem; 
}

.band-item span {
  display: none;
} */

.band-item {
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  background-color: var(--color-background-secondary, #f8f8f8);
  transition: all 0.2s ease;
  cursor: pointer;
  border: 1px solid var(--color-border, #eaeaea);
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.band-item:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.spotify-title {
  font-size: 0.95rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  margin-top: 0px;
}

.spotify-link {
  color: #4a4a4a;
  font-size: 0.9rem;
  font-weight: normal;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: color 0.2s ease;
}

.spotify-link:hover {
  color: #333333; /* Mantenemos el color original del hover */
}

.spotify-logo {
  width: 20px; /* Ajusta el tamaño según sea necesario */
  height: auto;
  margin-right: 8px; /* Espacio entre el logo y el texto */
  vertical-align: middle; /* Alinea el logo con el texto */
}

.external-link-icon {
  margin-left: 5px;
  font-size: 0.8rem;
  transition: transform 0.2s ease;
}

.band-item span {
  display: none;
}

.card-bottom {
  display: flex;
  flex-direction: column; /* Agrupa los elementos del footer */
  gap: 10px;
  background-color: #f7f7f7; /* Fondo gris claro */
  border-top: 1px solid #e0e0e0; /* Línea divisoria superior */
  padding: 10px 15px;
  margin-top: auto; /* Empuja el card-bottom hacia abajo */
}

/* footer de la card */

.card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 15px 10px;
  background-color: #f7f7f7; /* Fondo gris claro */
  border-top: 1px solid #e0e0e0; /* Línea divisoria superior */
  justify-content: flex-end;
}

.feedback-icons {
  display: flex;
  align-items: center;
  gap: 20px; /* Espaciado entre los iconos */
}

.feedback-text {
  font-size: 12px;
  font-weight: 500;
  color: #4a4a4a;
  margin-right: 15px;
}

/* .card-actions {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  gap: 10px;
} */

.card-actions-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.like-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Estilos para el botón de mostrar/ocultar filtros */
/* Contenedor del botón */
.filters-button-container {
  display: flex; /* Usamos flexbox para controlar la alineación */
  justify-content: flex-end; /* Alinea los elementos a la derecha */
  margin-bottom: 20px; /* Espaciado entre el botón y los filtros */
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
}

/* Botón de alternancia */
.toggle-filters-btn {
  background-color: #e6e3e3;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: none; /* Asegura que el contenido del botón esté centrado */
  align-items: center; /* Centra verticalmente el ícono */
  justify-content: center; /* Centra horizontalmente el ícono */
  width: 40px; /* Tamaño fijo para el botón, ajustable */
  height: 40px;
}

.toggle-filters-btn:hover {
  background-color: #cfcccc;
}

/* Ícono dentro del botón */
.filter-icon {
  width: 35px;
  height: 35px;
  display: block; /* Asegura que el ícono ocupe su espacio */
}

/* Estilos condicionales para filtros */
.filters-visible {
  display: grid;
}

.filters-hidden {
  display: none;
}

@media (max-width: 1150px) {
  .filters-container {
    grid-template-columns: repeat(2, 1fr); /* Ajuste a dos columnas */
    gap: 1.2rem;
    margin-left: 20px;
    margin-right: 20px;
    box-sizing: border-box;
  }

  .cards-grid {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* 3 columnas en pantallas medianas */
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .concert-card {
    font-size: 14px;
  }
  .filters-container {
    grid-template-columns: repeat(2, 1fr); /* Ajuste a dos columnas */
    gap: 1.2rem;
  }

  .filters-container label {
    text-align: center; /* Centrar texto en pantallas pequeñas */
  }
  .cards-grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 columnas en pantallas medianas */
  }
}

@media (max-width: 650px) {
  .filters-container {
    grid-template-columns: repeat(1, 1fr); /* Ajuste a una columna */
    gap: 1rem;
    margin-left: 5px;
    margin-right: 5px;
  }

  .toggle-filters-btn {
    display: flex;
  }

  .cards-title {
    display: none;
  }

  .cards-grid {
    grid-template-columns: repeat(
      1,
      1fr
    ); /* 1 columnas en pantallas medianas */
  }
}

/* Deshabilitar efectos de hover en pantallas táctiles */
@media (hover: none) {
  .concert-card {
    transform: none;
  }

  .concert-card:hover {
    transform: none;
  }

  .whatsapp-share-button {
    transform: none;
  }

  .filters-container input,
  .filters-container select {
    transform: none;
  }

  .band-item {
    transform: none;
  }

  .spotify-link {
    transform: none;
  }
}
