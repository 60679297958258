.header-link {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e0e0e0;
}

.header-link-logo {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #003366;
}

.header-link-logo img {
  width: 50px;
}

.header-link-logo h1 {
  margin: 0;
  font-size: 24px;

  color: #003366;
  font-family: "Lobster", serif;
  font-weight: 400;
  font-style: normal;
}
