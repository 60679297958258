.password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  max-width: 400px;
  margin: 60px auto;
}

.reset-title {
  font-size: 24px;
  font-weight: bold;
  color: #036;
  margin-bottom: 10px;
  font-family: "Lobster", serif;
}

.reset-instructions {
  font-size: 16px;
  color: #444;
  margin-bottom: 20px;
}

.reset-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos dentro del formulario */
  width: 100%;
  max-width: 350px; /* Ajuste para centrar mejor en pantallas grandes */
}

.reset-input {
  width: 100%;
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 30px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.reset-input:focus {
  border-color: #036;
  box-shadow: 0 0 5px rgba(0, 51, 102, 0.3);
}

.reset-button {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  background-color: #036;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

.reset-button:hover {
  background-color: #024;
}

.reset-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.reset-message {
  margin-top: 15px;
  font-size: 14px;
  padding: 10px;
  border-radius: 8px;
  max-width: 350px;
}

.success {
  color: #036;
  background-color: rgba(3, 54, 102, 0.1);
}

.error {
  color: red;
  background-color: rgba(255, 0, 0, 0.1);
}

/* Responsivo */
@media (max-width: 480px) {
  .password-reset-container {
    width: 90%;
    max-width: 100%;
    padding: 20px;
  }

  .reset-form {
    width: 100%;
  }

  .reset-input,
  .reset-button {
    font-size: 16px;
    padding: 12px;
  }
}
