/* Formulario sin cajas, minimalista */
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  max-width: 600px;
}

.login-register-title {
  font-size: 35px;
  color: #333;
  margin-bottom: 0px;
  font-family: "Lobster", serif;
  font-weight: 400;
  font-style: normal;
}

.login-register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 500px;
}

/* Entrada de email y contraseña */
.email-input {
  width: 100%;
  padding: 18px;
  border: 1px solid #333;
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
  background: white;
  color: black;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.email-input::placeholder {
  color: #999;
  font-size: 16px;
}

/* Efecto al enfocar */
.email-input:focus {
  border-color: #036;
  box-shadow: 0 0 8px rgba(0, 51, 102, 0.3);
}

/* Botón de registro/login */
.login-button {
  width: 100%;
  padding: 18px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  background: #036;
  color: white;
  transition: background 0.3s, transform 0.1s;
}

/* Efecto hover y click */
.login-button:hover {
  background: #024;
}

.login-button:active {
  transform: scale(0.98);
}

/* Estado deshabilitado */
.login-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Mensajes de error */
.message {
  font-size: 18px;
  font-weight: 500;
  color: #024;
  background-color: rgba(3, 54, 102, 0.1);
  padding: 12px 18px;
  margin-top: 20px;
  text-align: center;
  border-radius: 8px;
  border-left: 5px solid #036;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 380px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  animation: fadeIn 0.4s ease-in-out;
}

/* Animación */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Alternador entre login y registro */
.toggle-text {
  margin-top: 12px;
  font-size: 16px;
  color: #666;
}

.toggle-text span {
  color: #036;
  cursor: pointer;
  font-weight: bold;
}

.toggle-text span:hover {
  text-decoration: underline;
}

.forgot-password {
  margin-top: 10px;
  font-size: 14px;
}

.forgot-password a {
  color: #036;
  text-decoration: none;
  font-weight: bold;
}

.forgot-password a:hover {
  text-decoration: underline;
}

/* Responsivo */
@media (max-width: 550px) {
  .email-input {
    width: 80%;
    padding: 16px;
    font-size: 16px;
  }

  .login-button {
    width: 80%;
    padding: 16px;
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .email-input,
  .login-button {
    font-size: 14px;
    padding: 12px;
  }
}
