.about-us {
  padding: 40px;
  font-family: "Inter", sans-serif;
  color: #333333;
  line-height: 1.6;
  background-color: #f4f2ee;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.about-us h1 {
  color: #003366;
  font-size: 28px;
  margin-bottom: 20px;
}

.about-us p {
  font-size: 16px;
}
