/* Página de login minimalista */
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #faf8f6;
  font-family: "Arial", sans-serif;
  text-align: center;
  min-height: 100vh; /* Permite que el contenido fluya sin forzar el 100vh */
  padding: 40px 20px; /* Espaciado superior e inferior */
}

/* Encabezado con logo y nombre */
.header-top {
  display: flex;
  align-items: center;
  gap: 1px;
}

.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.header-title {
  font-size: 30px;
  font-weight: bold;
  color: #036;
  font-family: "Lobster", serif;
  font-weight: 400;
  font-style: normal;
}

.header-subtitle {
  margin-top: 0px;
  font-size: 25px;
  font-weight: normal;
  font-style: italic;
  color: rgb(73, 74, 74);
  font-family: "Lobster", serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

/* Contenido centrado */
.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 1500px;
  padding-top: 40px;
  padding-bottom: 40px; /* Espaciado para evitar que los elementos se junten */
}

/* Título principal */
.login-title {
  font-size: 50px;
  color: #333;
  margin-bottom: 35px;
  font-weight: 700;
  font-family: "Lobster", serif;
  font-weight: 400;
  font-style: normal;
}

/* Subtítulo */
.login-subtitle {
  font-size: 24px;
  color: #444;
  margin-top: 10px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}

/* Contenedor del formulario */
.login-registration-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  max-width: 600px; /* Aumentado para mejorar legibilidad */
}

/* Campo de email */
.email-input {
  width: 100%;
  padding: 20px; /* Más grande para facilitar la escritura */
  border: 1px solid #333;
  border-radius: 35px;
  font-size: 20px; /* Texto grande y fácil de leer */
  text-align: center;
  background: white;
  color: black;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.email-input::placeholder {
  color: #999;
  font-size: 18px;
}

/* Resalta el campo de email cuando está en foco */
.email-input:focus {
  border-color: #036;
  box-shadow: 0 0 8px rgba(0, 51, 102, 0.3);
}

/* Botón de login */
.login-button {
  width: 100%;
  padding: 20px; /* Botón más grande */
  border: none;
  border-radius: 35px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  background: #036;
  color: white;
  transition: background 0.3s, transform 0.1s;
}

/* Efecto de hover para el botón */
.login-button:hover {
  background: #024;
}

/* Efecto de clic */
.login-button:active {
  transform: scale(0.98);
}

/* Deshabilitado */
.login-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Mensaje de error o confirmación */
.message {
  font-size: 20px;
  font-weight: 500;
  color: #024;
  background-color: rgba(3, 54, 102, 0.1); /* Azul translúcido */
  padding: 15px 20px;
  margin-top: 30px;
  text-align: center;
  border-radius: 8px;
  border-left: 5px solid #036; /* Línea lateral elegante */
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 420px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease-in-out;
}

/* Animación de aparición sutil */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message {
  animation: fadeIn 0.4s ease-in-out;
}

/* Separador con línea */
.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  font-size: 14px;
  color: #777;
  width: 100%;
  max-width: 400px;
}

.separator::before,
.separator::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: #ddd;
  margin: 0 10px;
}

/* Botón de login con Spotify */
.spotify-login {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #444;
  color: white;
  padding: 16px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  transition: background 0.3s;
}

.spotify-login:hover {
  background: #222;
}

.spotify-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Footer con opción para bandas */
.invite-bands {
  bottom: 20px;
  font-size: 14px;
  color: #555;
}

.invite-bands a {
  color: #036;
  text-decoration: none;
  font-weight: bold;
}

.invite-bands a:hover {
  text-decoration: underline;
}

/* Responsivo */

@media (max-width: 700px) {
  .login-title {
    font-size: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .login-subtitle {
    font-size: 16px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .login-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .login-page {
    padding: 20px 10px;
  }

  .invite-bands {
    position: relative;
    margin-top: 30px;
  }
}

@media (max-width: 550px) {
  .email-input {
    width: 80%;
    padding: 16px;
    font-size: 18px;
  }

  .email-input::placeholder {
    font-size: 16px;
  }

  .login-button {
    width: 80%;
    padding: 16px;
    font-size: 18px;
    transition: none;
  }

  /* Efecto de clic */
  .login-button:active {
    transform: none;
  }

  /* Deshabilitado */
  .login-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  /* Mensaje de error o confirmación */
  .message {
    font-size: 20px;
    margin-top: 20px;
  }

  /* Separador con línea */
  .separator {
    margin: 20px 0;
    font-size: 12px;
  }

  /* Botón de login con Spotify */
  .spotify-login {
    padding: 12px;
    font-size: 16px;
    width: 100%;
    max-width: 300px;
  }

  .spotify-login:hover {
    background: none;
  }

  .spotify-icon {
    width: 24px;
    margin-right: 10px;
  }
  .header-title {
    font-size: 24px;
  }
}

@media (max-width: 410px) {
  .login-logo {
    width: 50px;
    height: 50px;
  }

  .header-title {
    font-size: 24px;
  }
  .header-title {
    font-size: 20px;
  }

  .login-content {
    width: 90%;
    margin-bottom: 50px;
  }
}

@media (max-width: 320px) {
  .login-page {
    padding: 10px;
  }

  .login-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .header-logo {
    width: 50px;
    height: 500px;
  }

  .email-input,
  .login-button,
  .spotify-login {
    max-width: 100%;
  }

  .message {
    font-size: 18px;
    padding: 12px;
  }
  .header-title {
    display: none;
  }
}

@media (max-width: 270px) {
  .login-header {
    display: flex;
    align-items: center; /* Centra los elementos dentro del contenedor */
    justify-content: center; /* Centra el contenedor si su padre usa flex */
    text-align: center;
  }

  .header-title {
    font-size: 16px;
  }

  .email-input,
  .login-button,
  .spotify-login {
    font-size: 14px;
    padding: 12px;
  }

  .login-subtitle {
    display: none;
  }
}
