.carousel-container-custom {
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  padding: 20px 0;
  margin-top: 50px;
}

.carousel-title-custom {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  font-family: "Lobster", serif;
}

.carousel-track-custom {
  display: flex;
  gap: 20px;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
  animation: carousel-loop-custom 50s linear infinite;
}

@keyframes carousel-loop-custom {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.carousel-card-custom {
  flex: 0 0 20%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  text-align: left;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.carousel-card-custom:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.carousel-date-custom {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.carousel-band-custom {
  font-size: 21px;
  font-weight: 600;
  margin: 10px 0;
  color: #333;
}

.carousel-genre-custom,
.carousel-venue-custom,
.carousel-time-custom,
.carousel-price-custom {
  font-size: 14px;
  color: #666;
  margin: 2px 0;
  text-align: left;
}

.carousel-price-custom {
  font-weight: bold;
  color: #333;
}

@media (max-width: 900px) {
  .carousel-card-custom {
    flex: 0 0 25%;
  }
}

@media (max-width: 600px) {
  .carousel-card-custom {
    flex: 0 0 50%;
  }
}
