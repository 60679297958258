.like-section {
  display: flex;
  align-items: center;
  gap: 5px;
}

.like-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.like-icon:hover {
  transform: scale(1.2);
}

.like-count {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

/* Deshabilitar efectos de hover en pantallas táctiles */
@media (hover: none) {
  .like-icon {
    transition: none;
  }

  .like-icon:hover {
    transform: none;
  }
}
