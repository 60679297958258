/* Contenedor principal */
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
  background-color: #f4f2ee;
}

/* Contenido del perfil */
.profile-content {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.profile-content h2 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 20px;
}

/* Lista de detalles del perfil */
.profile-details {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
}

.profile-details li {
  font-size: 16px;
  margin: 10px 0;
  color: #555555;
}

.profile-details li strong {
  font-weight: 600;
  color: #333333;
}

/* Danger Zone */
.danger-zone {
  background-color: #fff5f5;
  border: 1px solid #f5c2c2;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
}

.danger-title {
  color: #d9534f;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.danger-description {
  color: #d9534f;
  font-size: 14px;
  margin-bottom: 20px;
}

/* Botón de eliminar cuenta */
.delete-account-button {
  background-color: #d9534f;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 200px; /* Ajusta el ancho del botón */
  margin: 0 auto; /* Centra horizontalmente dentro de su contenedor */
  display: block; /* Asegura que respete las reglas de centrado */
  text-align: center; /* Centra el texto del botón */
}

.delete-account-button:hover {
  background-color: #c9302c; /* Cambia el color al pasar el cursor */
}

.delete-account-button:active {
  transform: scale(0.95); /* Efecto de clic */
}

/* Errores */
.error {
  color: #d9534f;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}

.custom-link {
  text-decoration: underline; /* Subrayar el texto */
  color: inherit; /* Mantener el color del texto original */
  cursor: pointer; /* Cambiar el cursor al pasar el ratón */
  transition: text-decoration 0.2s ease; /* Suavizar el efecto */
}

.custom-link:hover {
  text-decoration: none; /* Quitar subrayado al pasar el cursor */
}

.go-to-concerts-button {
  background-color: #f7f7f7;
  color: #ffffff; /* Texto blanco */
  border: 0.5px solid #4a4a4a;
  color: #4a4a4a;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 20px auto; /* Centrar el botón horizontalmente */
  display: block; /* Centrar dentro del contenedor */
  width: 220px; /* Ajusta el ancho del botón */
  margin: 0 auto; /* Centra horizontalmente dentro de su contenedor */
  text-align: center; /* Centra el texto del botón */
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-bottom: 100px; /* Espaciado inferior */
}

.go-to-concerts-button:hover {
  background-color: #ebe8e8;
}

.go-to-concerts-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.styled-span {
  margin-bottom: 10px; /* Espaciado inferior */
  line-height: 1.6; /* Altura de línea para mejor legibilidad */
  font-size: 16px; /* Tamaño del texto */
  color: #333; /* Color de texto legible */
}

.styled-span-red {
  margin-bottom: 10px; /* Espaciado inferior */
  line-height: 1.6; /* Altura de línea para mejor legibilidad */
  font-size: 16px; /* Tamaño del texto */
  color: #d9534f; /* Color de texto legible */
}

/* Inputs editables - Diseño moderno */
.profile-details input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  outline: none;
  display: block;
  margin-top: 5px;
}

.profile-details input:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 8px rgba(74, 144, 226, 0.3);
}

/* Botón de editar - Efecto moderno */
.edit-button {
  background: rgba(74, 144, 226, 0.15);
  color: #4a90e2;
  border: 0.5px solid #4a90e2;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(10px);
  display: block;
  width: 220px;
  margin: 20px auto;
  margin-bottom: 50px;
  text-align: center;
}

.edit-button:hover {
  background: #4a90e2;
  color: white;
  box-shadow: 0px 4px 12px rgba(74, 144, 226, 0.3);
}

.edit-button:active {
  transform: scale(0.96);
}

/* Botón de guardar - Se siente responsive y moderno */
.save-button {
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  display: block;
  width: 220px;
  margin: 20px auto;
  text-align: center;
}

.save-button:hover {
  background: #45a049;
}

.save-button:active {
  transform: scale(0.96);
}

.password-change-section {
  margin-top: 20px;
  text-align: center;
}

.password-input {
  width: 100%;
  max-width: 300px;
  padding: 12px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}

.password-button {
  margin-top: 10px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background-color: #036;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

.password-button:hover {
  background-color: #024;
}

.success-message {
  color: #036;
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 10px;
}
