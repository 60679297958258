.footer {
  padding: 40px 40px 20px;
  background-color: #faf8f6;
  border-top: 1px solid #e0e0e0;
  color: #333333;
  font-family: "Inter", sans-serif;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; /* Espacia los elementos */
  align-items: flex-start; /* Alinea los elementos al inicio */
  flex-wrap: wrap; /* Permite ajuste en pantallas pequeñas */
}

.footer-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  max-width: 50px;
  margin-bottom: 10px;
}

.footer-logo-link {
  text-decoration: none;
  color: inherit;
}

.footer-logo-link img {
  width: 120px;
}

.footer-logo-link h3 {
  margin: 0;
  font-size: 20px;
  color: #003366;
  font-family: "Lobster", serif;
  font-weight: 400;
  font-style: normal;
}

.footer-links {
  flex: 1;
  text-align: right;
  min-width: 200px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; /* Organiza los elementos en fila */
  flex-direction: column;
  gap: 10px; /* Espaciado entre elementos */
}

.footer-links li {
  margin: 0; /* Asegúrate de no tener margen adicional */
}

.footer-links a {
  color: #999999;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-text {
  flex-basis: 100%; /* Ocupa toda la fila */
  text-align: center;
  margin: 20px 0 10px;
  font-size: 14px;
  color: #666666;
}

.footer-bottom {
  text-align: center;
  font-size: 12px;
  color: #999999;
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
}

.footer-button {
  background-color: #e8e8e8; /* Color neutro para menos énfasis */
  color: #666666;
  border: 1px solid #d0d0d0; /* Borde suave */
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 200px; /* Ancho máximo del botón */
  width: 100%; /* El botón ocupará el 100% del contenedor hasta el máximo */
  text-align: center; /* Centra el texto */
  margin-left: auto; /* Empuja el botón hacia la derecha */
}

.footer-button:hover {
  background-color: #d0d0d0; /* Cambio sutil en el hover */
  color: #666666;
}

.footer-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 750px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-logo,
  .footer-links {
    text-align: center;
  }

  .footer-links {
    margin-top: 20px;
  }
}
