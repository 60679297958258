.header {
  display: flex;
  justify-content: space-between;
  background-color: #faf8f6; /* Fondo blanco */
  border-bottom: 1px solid #e0e0e0; /* Línea divisora */
}

.header-container {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  background-color: #faf8f6;
  width: 100%;
  justify-content: space-between;
}

.user-data-branding {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-data-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 0px;
}

.user-data-brand {
  font-size: 25px;
  color: #003366;
  font-weight: bold;
  font-family: "Lobster", serif;
  font-weight: 400;
  font-style: normal;
  white-space: nowrap;
}

.user-data-information {
  display: flex;
  /* align-items: center; */
  gap: 20px;
  flex-wrap: nowrap; /* Evita estiramientos si hay espacio limitado */
}

.user-data-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.user-name-placeholder {
  font-size: 20px; /* Tamaño de la letra */
  font-weight: 700; /* Negrita */
  color: #555; /* Color del texto */
  text-transform: uppercase; /* Convierte la letra a mayúsculas */
}

.header-left {
  display: flex;
  align-items: center;
}

.header-logo {
  height: 40px;
  margin-right: 10px;
}

.header-brand-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.header-user-name {
  font-size: 16px;
  margin-right: 10px;
  color: #333;
}

.header-logout-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.header-logout-button:hover {
  background-color: #0056b3;
}

.header-error {
  color: red;
  font-size: 14px;
  text-align: center;
}

.header-loading {
  font-size: 14px;
  color: #666;
}

.loading-mic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.user-data-profile-button {
  background-color: #003366;
  color: #ffffff;
  font-size: 14px;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-data-logout-button {
  background-color: #525355;
  color: #ffffff;
  font-size: 14px;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-data-profile-button:hover {
  background-color: #00254a;
}

.user-data-logout-button:hover {
  background-color: #3c3c3d;
}

@media (max-width: 750px) {
  .user-data-brand {
    display: none;
  }
  .user-data-name {
    display: none;
  }
}

@media (max-width: 550px) {
  .user-data-brand {
    display: none;
  }
}

@media (max-width: 300px) {
  .user-data-avatar {
    display: none;
  }

  .user-name-placeholder {
    display: none;
  }
}
