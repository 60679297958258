.html {
  background-color: #f4f2ee;
}

.body {
  margin: 0;
  padding: 0;
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-weight: 500;
  color: #333333;
  background-color: #f4f2ee;
}

.page {
  background-color: #f4f2ee;
  margin: auto;
  font-family: "Cabinet Grotesk", "Arial", sans-serif;
  font-weight: 500;
  min-height: 100vh;
  color: #333333;
}

.page__content {
  max-width: 1440px;
  min-width: 320px;
  margin: auto;
}
