/* Contenedor principal */
.profile-setup-container {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Título */
.profile-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  max-width: 90%;
}

/* Subtítulo debajo del título */
.profile-subtitle {
  font-size: 16px;
  color: #555;
  text-align: center;
  max-width: 90%;
  margin: -10px auto 20px auto; /* Espacio negativo arriba para acercarlo al título */
  padding: 0 10px;
  line-height: 1.4;
}

/* Imagen de perfil */
.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #036;
  margin-bottom: 15px;
  object-fit: cover;
}

/* Información del perfil */
.profile-info {
  text-align: left;
  width: 100%;
  max-width: 450px;
  background: white;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  line-height: 1.5;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
}

.profile-info p {
  margin: 8px 0;
  color: #333;
}

.profile-info strong {
  color: #036;
}

/* Campos editables */
.editable-label {
  display: block;
  font-size: 16px;
  margin-top: 10px;
  color: #036;
}

.editable-input {
  width: 100%;
  max-width: 450px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-top: 5px;
  box-sizing: border-box;
}

.editable-input:focus {
  outline: none;
  border-color: #036;
  box-shadow: 0 0 6px rgba(3, 54, 102, 0.5);
}

/* Botón de guardar cambios */
.save-button {
  display: block;
  width: 100%;
  max-width: 450px;
  padding: 12px;
  margin-top: 15px;
  background: #28a745;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.save-button:hover {
  background: #218838;
  transform: scale(1.02);
}

/* Botón de siguiente */
.next-button {
  display: block;
  width: 100%;
  max-width: 450px;
  padding: 12px;
  margin-top: 15px;
  background: #036;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.next-button:hover {
  background: #024;
  transform: scale(1.02);
}

.wide-input {
  width: 100%;
  max-width: 450px;
  height: 48px; /* Espacio suficiente para dos líneas */
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-top: 5px;
  box-sizing: border-box;
  white-space: normal; /* Permite que el texto haga wrap */
  overflow: hidden; /* Oculta el texto si excede dos líneas */
  text-overflow: ellipsis; /* Agrega "..." si se excede */
  line-height: 1.4;
}

/* Cuando el campo está enfocado, que el texto se vea completo */
.wide-input:focus {
  outline: none;
  border-color: #036;
  box-shadow: 0 0 6px rgba(3, 54, 102, 0.5);
  height: auto; /* Se expande temporalmente al hacer focus */
  overflow: visible;
}

/* Campos editables con wrap y altura fija para 2 líneas */
.fixed-height {
  width: 100%;
  max-width: 450px;
  height: calc(1.5em * 2 + 10px); /* Espacio para 2 líneas */
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-top: 5px;
  box-sizing: border-box;
  white-space: normal;
  line-height: 1.5;
  overflow: hidden; /* Oculta el texto si hay más de 2 líneas */
  resize: none; /* Evita que el usuario lo redimensione manualmente */
}

/* Cuando el usuario hace focus, el campo se expande */
.fixed-height:focus {
  height: auto; /* Permite ver todo el texto */
  overflow: visible;
  outline: none;
  border-color: #036;
  box-shadow: 0 0 6px rgba(3, 54, 102, 0.5);
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 480px) {
  .wide-input {
    font-size: 14px;
    height: 40px; /* Ajuste para pantallas pequeñas */
  }
  .fixed-height {
    font-size: 14px;
    height: calc(1.4em * 2 + 10px);
  }
}
